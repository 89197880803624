import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const productApi = createApi({
  reducerPath: "productApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
        ? getState().auth.token
        : getState().auth.technicianToken;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllProducts: builder.query({ query: () => `products` }),
    getProduct: builder.query({ query: (id) => `products/${id}` }),
  }),
});

export const { useGetAllProductsQuery, useGetProductQuery } = productApi;
