import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const addTreatment = createAsyncThunk(
  "treatment/create",
  async (
    {
      title,
      description,
      date,
      service,
      season,
      tags,
      additional_price,
      first_tree_price,
      general_tree_price,
      is_active,
      subject,
      form_text,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        "treatments",
        {
          title,
          description,
          date,
          service,
          season,
          tags,
          additional_price,
          first_tree_price,
          general_tree_price,
          is_active,
          subject,
          form_text,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTreatment = createAsyncThunk(
  "treatment/update",
  async (
    {
      id,
      title,
      description,
      date,
      service,
      season,
      tags,
      additional_price,
      first_tree_price,
      general_tree_price,
      is_active,
      subject,
      form_text,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.patch(
        `treatments/${id}`,
        {
          title,
          description,
          date,
          service,
          season,
          tags,
          additional_price,
          first_tree_price,
          general_tree_price,
          is_active,
          subject,
          form_text,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTreatment = createAsyncThunk(
  "treatment/delete",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.delete(
        `treatments/${id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendTreatment = createAsyncThunk(
  "treatment/sendBulk",
  async (
    { treatment_id, cust_id_arr, dont_send_mail },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        `email/send-treat-form`,
        { treatment_id, cust_id_arr, dont_send_mail },
        config
      );
      return { data, status };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendBulkTreatmentOperations = createAsyncThunk(
  "treatment/send",
  async (
    { treatment_id, cust_id_arr, dont_send_mail },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        `treatment-ops/bulk`,
        { treatment_id, cust_id_arr, dont_send_mail },
        config
      );
      return { data, status };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const wantTreatment = createAsyncThunk(
  "treatment/want",
  async (
    { operationId, want_treat_response },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.patch(
        `treatment-ops/want-treat/${operationId}`,
        { want_treat_response },
        config
      );
      return { data, status };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
