import React from "react";
import TechnicianHomePage from "../../pages/TechnicianHomePage";

const TechnicianApp = ({ id }) => {
  return (
    <div className="overflow-auto max-h-96">
      <TechnicianHomePage id={id} />
    </div>
  );
};

export default TechnicianApp;
