import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";

import Header from "../Header";
import TechnicianApp from "./TechnicianApp";

import { getFormErrorMessage } from "../../utils/getFormErrorMessage";
import { useGetAllTechniciansQuery } from "../../services/technician/technicianServices";

const TechnicianAppContainer = () => {
  const [id, setId] = useState(null);

  const dispatch = useDispatch();

  const { assignTechnician: assignTechnicianState } = useSelector(
    (state) => state.operation
  );

  const defaultValues = {
    technicianId: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const { data: techniciansData, isLoading: techniciansLoading } =
    useGetAllTechniciansQuery("TechniciansDetails", {
      refetchOnMountOrArgChange: true,
    });

  const onSubmit = (data) => {
    setId(data?.technicianId?.code);
  };

  return (
    <div className={` h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Technicians"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <div className="flex justify-end w-full">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex items-start gap-2 my-8"
              >
                <Controller
                  name="technicianId"
                  control={control}
                  rules={{ required: "Technician is required." }}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col ">
                      <span>
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          options={
                            techniciansData
                              ? techniciansData.data.map((technician) => ({
                                  name: technician.full_name,
                                  code: technician.id,
                                }))
                              : []
                          }
                          loading={techniciansLoading}
                          optionLabel="name"
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full rounded-xl`}
                          placeholder="Select a Technician"
                        />
                      </span>
                      {console.log({ errors: errors.length })}
                      {errors?.length !== 0 &&
                        getFormErrorMessage(field.name, errors)}
                    </div>
                  )}
                />
                <Button
                  label={"Submit"}
                  className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                  type="submit"
                />
              </form>
            </div>
            {id ? (
              <TechnicianApp id={id} />
            ) : (
              <p className="text-center">Please select a technician</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicianAppContainer;
