import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { getFormErrorMessage } from "../../utils/getFormErrorMessage";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../features/customer/customerActions";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

const EditCustomerForm = ({
  data: CustomerData,
  isLoading,
  error,
  refetch,
  toast,
  setState,
}) => {
  console.log({ CustomerData });
  const dispatch = useDispatch();
  const { updateLoading } = useSelector((state) => state.customer);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const locationOptions = [
    { name: "Nassau or Suffolk County", code: "Nassau or Suffolk County" },
    { name: "Other", code: "Other" },
  ];

  useEffect(() => {
    if (CustomerData?.data) {
      reset({
        firstName: CustomerData?.data?.first_name || "",
        lastName: CustomerData?.data?.last_name || "",
        email: CustomerData?.data?.email || "",
        phone: CustomerData?.data?.phone || "",
        street: CustomerData?.data?.street || "",
        state: CustomerData?.data?.state || "",
        town: CustomerData?.data?.town || "",
        postalCode: CustomerData?.data?.postal_code || "",
        secondaryFirstName: CustomerData?.data?.sec_first_name || "",
        secondaryLastName: CustomerData?.data?.sec_last_name || "",
        secondaryEmail: CustomerData?.data?.sec_email || "",
        secondaryPhone: CustomerData?.data?.sec_phone || "",
        haveWindmill: CustomerData?.data?.ordered_windmill?.toString(),
        contactAgain: CustomerData?.data?.do_not_contact_again?.toString(),
        prevTreatment: CustomerData?.data?.prev_treatements?.toString(),
        to_do_notes: CustomerData?.data?.to_do_notes || "",
        location:
          {
            name: CustomerData?.data?.location,
            code: CustomerData?.data?.location,
          } || "",
      });
    }
  }, [reset, CustomerData?.data]);

  const onEdit = (data) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      street,
      state,
      town,
      postalCode,
      haveWindmill,
      contactAgain,
      prevTreatment,
      to_do_notes,
      location,
    } = data;

    const obj = {
      id: CustomerData?.data?.id,
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
      postal_code: postalCode,
      state,
      street,
      town,
      ordered_windmill: haveWindmill === "true",
      do_not_contact_again: contactAgain === "true",
      prev_treatements: prevTreatment === "true",
      to_do_notes,
      location: location?.name,
    };

    if (data.secondaryFirstName && data.secondaryFirstName !== "") {
      obj.sec_first_name = data.secondaryFirstName;
    }
    if (data.secondaryLastName && data.secondaryLastName !== "") {
      obj.sec_last_name = data.secondaryLastName;
    }

    if (data.secondaryEmail && data.secondaryEmail !== "") {
      obj.sec_email = data.secondaryEmail;
    }

    if (data.secondaryPhone && data.secondaryPhone !== "") {
      obj.sec_phone = data.secondaryPhone;
    }

    dispatch(updateCustomer(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        reset();
        refetch();
        setState("CustomerDetails");
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onEdit)} className="my-8">
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    First Name
                  </label>
                  <span>
                    <InputText
                      id={field.name || ""}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="First Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Last Name
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Last Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Email
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Email"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Phone
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Phone"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <Controller
            name="location"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Location
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.value)}
                    options={locationOptions}
                    optionLabel="name"
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="street"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Street
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled
                      placeholder="Street"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="state"
              control={control}
              // rules={{ }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    State
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled
                      placeholder="State"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="town"
              control={control}
              // rules={{ }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Town
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled
                      placeholder="Town"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="postalCode"
              control={control}
              // rules={{ }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Postal Code
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      disabled
                      placeholder="Postal Code"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="secondaryFirstName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other First Name
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other First Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="secondaryLastName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other Second Name
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other Last Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="secondaryEmail"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other Email
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other Email"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="secondaryPhone"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other Phone
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other Phone"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <Controller
            name="to_do_notes"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Notes
                </label>
                <span>
                  <InputTextarea
                    id={field.name}
                    cols={30}
                    rows={4}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} resize-none w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Notes"
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="contactAgain"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Do Not Contact Again
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Yes"
                        name="category"
                        value={"true"}
                        checked={field.value === "true"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Yes" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="No"
                        name="category"
                        value={"false"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={field.value === "false"}
                      />
                      <label htmlFor="No" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="haveWindmill"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Have Windmill
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Yes"
                        name="category"
                        value={"true"}
                        checked={field.value === "true"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Yes" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="No"
                        name="category"
                        value={"false"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={field.value === "false"}
                      />
                      <label htmlFor="No" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="prevTreatment"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Made Prev Treatment
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Yes"
                        name="category"
                        value={"true"}
                        checked={field.value === "true"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Yes" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="No"
                        name="category"
                        value={"false"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={field.value === "false"}
                      />
                      <label htmlFor="No" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-center">
            <Button
              label={updateLoading ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={updateLoading ? "pi pi-spin pi-spinner" : "pi pi-check"}
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </>
  );
};

export default EditCustomerForm;
