import atob from "atob";

export const fetchCoordinatesFromGeoCode = async (data) => {
  let Markers = [];
  for (let i = 0; i < data?.length; i++) {
    const geoCode = data[i]?.customer?.geo_code;
    const id = data[i]?.id;
    const status = data[i]?.status;
    const wantTreatRes = data[i]?.want_treat_response;
    const paymentStatus = data[i]?.order?.payment_status;
    const technicianData = data[i]?.technician;
    const customerData = data[i]?.customer;

    if (!geoCode) {
      continue;
    }

    let decodedGeoCode;
    try {
      decodedGeoCode = atob(geoCode);
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      continue;
    }

   
    if (!decodedGeoCode.startsWith("{") || !decodedGeoCode.endsWith("}")) {
      console.error("Decoded string is not a valid JSON object:", decodedGeoCode);
      continue;
    }

    let geoData;
    try {
      geoData = JSON.parse(decodedGeoCode);
    } catch (error) {
      console.error("Error parsing JSON string:", error);
      continue;
    }

    if (!geoData.o || !geoData.o.lat || !geoData.o.lng) {
      continue;
    }

    const { lat, lng } = geoData.o;
    if (
      typeof lat === "number" &&
      typeof lng === "number" &&
      isFinite(lat) &&
      isFinite(lng)
    ) {
      if (wantTreatRes === "true" || wantTreatRes === "no-answer" || wantTreatRes === undefined) {
        Markers.push({ lat, lng, id, status, wantTreatRes, paymentStatus, technicianData, customerData });
      }
    } else {
      console.error("Invalid coordinates:", lat, lng);
    }
  }

  return Markers;
};