import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { FilterMatchMode } from "primereact/api";

import img from "../../src/assets/tree.png";
import userPhoto from "../../src/assets/user.png";

import { logout } from "../features/auth/authSlice";
import {
  useGetAllOperationWithDoneSequenceQuery,
  // useGetAllOperationForTechnicianQuery,
  useGetAllOperationWithSequenceQuery,
} from "../services/operation/operationForTechnician";

import OperationDialog from "./../components/ClientTreatment/OperationDialog";

const TechnicianHomePage = ({ id = null }) => {
  const [filters] = useState({
    "treatment.title": { value: null, matchMode: FilterMatchMode.CONTAINS },
    want_treat_response: { value: null, matchMode: FilterMatchMode.EQUALS },
    "customer.state": { value: null, matchMode: FilterMatchMode.IN },
    "customer.town": { value: null, matchMode: FilterMatchMode.IN },
  });
  const [operationId, setOperationId] = useState("");
  const [operationDetailsVisible, setOperationDetailsVisible] = useState(false);
  const [toggle, setToggle] = useState(true);

  const { technicianToken } = useSelector((state) => state.auth);
  const decodedToken = technicianToken && jwtDecode(technicianToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log({ decodedToken });

  const handleLogOut = () => {
    dispatch(logout());
    navigate("/login");
  };

  const onShowOperationDetails = (id) => {
    setOperationId(id);
    setOperationDetailsVisible(true);
  };

  const navigateOrdersMap = () => {
    navigate("/technician/ordersmap");
  };

  const navigateMap = () => {
    navigate("/technician/map");
  };

  const {
    data: allOperationData,
    isLoading,
    error: operationTechnicianError,
    refetch,
  } = useGetAllOperationWithSequenceQuery(id ? id : decodedToken.id, {
    refetchOnMountOrArgChange: true,
  });

  const [data, setData] = useState(allOperationData?.data);

  console.log({ data });

  const {
    data: allOperationDoneData,
    isLoading: operationDoneLoading,
    error: operationDoneError,
  } = useGetAllOperationWithDoneSequenceQuery(id ? id : decodedToken.id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isLoading && toggle) {
      setData(allOperationData?.data);
    } else if (!operationDoneLoading && !toggle) {
      setData(allOperationDoneData?.data);
    }
  }, [
    allOperationData?.data,
    allOperationDoneData?.data,
    toggle,
    isLoading,
    operationDoneLoading,
  ]);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const toast = useRef(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  if (operationTechnicianError) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{operationTechnicianError?.data?.message}</p>
      </div>
    );
  }

  if (operationDoneError) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{operationDoneError?.data?.message}</p>
      </div>
    );
  }

  const customerTreeBodyTemplate = (rowData) => {
    const { total_number_of_trees, customer, want_treat_response } = rowData;
    return (
      <div className="flex items-center justify-start my-4 ml-2 text-sm">
        <i
          className={`${classNames("pi", {
            "pi-circle-fill mr-1 text-blue-600": want_treat_response === "true",
            "pi-circle-fill mr-1 text-red-500": want_treat_response === "false",
            "pi-circle-fill mr-1 text-gray-600":
              want_treat_response === "no-answer",
          })}`}
        ></i>
        {total_number_of_trees} - {customer.full_name}
      </div>
    );
  };

  const orderStatusBodyTemplate = (rowData) => {
    const { order } = rowData;
    return (
      <div className="flex items-center justify-center mx-4 text-sm">
        {order?.payment_status}
      </div>
    );
  };
  console.log({ allOperationData: allOperationData?.data });

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({ orientation: "landscape" });

        const exportColumns = [
          { header: "Sequence Number", dataKey: "road_sequence" },
          { header: "Trees - Customer", dataKey: "customer.full_name" },
          { header: "Address", dataKey: "customer.full_address" },
          { header: "Date", dataKey: "date_of_tech_visit" },
          { header: "Phone", dataKey: "customer.phone" },
          { header: "Broken Frame", dataKey: "customer.broken_frames" },
          { header: "Tallest Frame", dataKey: "customer.tallest_frame" },
          { header: "Notes", dataKey: "notes_comments" },
          { header: "Payment Status", dataKey: "order.payment_status" },
        ];

        const data = allOperationData?.data
          .map((op) => ({
            road_sequence: op?.road_sequence,
            "customer.full_name": `${op?.total_number_of_trees} - ${op?.customer?.full_name}`,
            date_of_tech_visit: op?.date_of_tech_visit,
            "customer.full_address": `${op?.customer?.full_address}`,
            "customer.phone": op?.customer?.phone,
            "customer.broken_frames": op?.customer?.broken_frames,
            "customer.tallest_frame": op?.customer?.tallest_frame,
            notes_comments: op?.notes_comments,
            "order.payment_status": op?.order?.payment_status,
          }))
          .sort((a, b) => {
            // Assuming date_of_tech_visit is in a format that can be parsed by Date
            return (
              new Date(a.date_of_tech_visit) - new Date(b.date_of_tech_visit)
            );
          });

        doc.autoTable({
          head: [exportColumns.map((col) => col.header)],
          body: data.map((row) => exportColumns.map((col) => row[col.dataKey])),
        });

        doc.save("technician_operations.pdf");
      });
    });
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between mb-2 bg-none">
        <div className="flex flex-col gap-3 md:flex-row  items-center justify-between w-full bg-[#6e9989]">
          <span className="w-full mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="w-full p-inputtext-sm rounded-xl lg:w-1/3"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
          <div className="flex gap-2">
            <Button
              icon="pi pi-sliders-h"
              className="bg-blue-500 border-blue-500"
              onClick={() => {
                setToggle(!toggle);
              }}
              rounded
            />
            <Button
              icon="pi pi-file-pdf"
              className="bg-[#F40F02] border-[#F40F02]"
              onClick={exportPdf}
              rounded
            />
            <Button
              icon="pi pi-dollar"
              className="bg-[#85bb65] border-[#85bb65]"
              onClick={() => {
                navigate("/technician/cash");
              }}
              rounded
            />
          </div>
        </div>
      </div>
    );
  };

  const headerTemplate = (data) => {
    return (
      <div className="flex items-center bg-[#FFEDED] justify-start w-full gap-2 rounded-xl">
        <span className="p-4 font-bold ">
          {data?.date_of_tech_visit ? data?.date_of_tech_visit : "No Date Yet"}
        </span>
      </div>
    );
  };

  return (
    <div className="bg-[#3A5D4C] p-2 flex flex-col">
      {decodedToken && (
        <div className="flex items-center justify-between pt-2 bg-[#FFEDED] px-10 rounded-t-[15.83px]">
          <div className="flex justify-center items-end  gap-2 md:border-b-[1.5px] mb-3 border-[#3A5D4C]">
            <img src={img} alt="logo" className="w-[50px] h-[50px]" />
            <span className="text-[#165720] font-bold text-[28px] leading-6 mb-2 hidden md:block">
              Palm Trees
            </span>
          </div>
          <div className="flex gap-5">
            <button
              className="items-center gap-2 lg:flex lg:justify-center bg-[#6e9989] text-white w-10 md:w-40 rounded-md"
              onClick={navigateOrdersMap}
            >
              <i className="text-xl font-semibold pi pi-map"></i>
              <span className="hidden text-xl lg:flex lg:font-semibold">
                Orders Map
              </span>
            </button>
            <button
              className="items-center gap-2 lg:flex lg:justify-center bg-[#6e9989] text-white w-10 lg:w-44 rounded-md"
              onClick={navigateMap}
            >
              <i className="text-xl font-semibold pi pi-map-marker"></i>
              <span className="hidden text-xl lg:flex lg:font-semibold">
                Operation Map
              </span>
            </button>
            <div className="flex items-center justify-center gap-2">
              <span className="text-[#165720] font-black text-[15px] leading-4 lg:flex hidden">
                {decodedToken?.username}
              </span>
              <img
                src={userPhoto}
                alt="logo"
                className="w-[40px] h-[40px] md:mb-2"
              />
            </div>

            <button className="flex items-center gap-2 " onClick={handleLogOut}>
              <span className="hidden md:block">LogOut</span>
              <i className="pi pi-sign-out "></i>
            </button>
          </div>
        </div>
      )}
      <div className="bg-[#FFEDED] w-full rounded-b-[15.83px] ">
        <div className="bg-[#6e9989] mx-5 rounded-[15.83px] mb-5 px-5 py-10">
          <div className="flex justify-center h-full">
            <Toast ref={toast} />
            {operationId && (
              <OperationDialog
                visible={operationDetailsVisible}
                setVisible={setOperationDetailsVisible}
                toast={toast}
                id={operationId}
                setId={setOperationId}
                refetch={refetch}
              />
            )}
            <DataTable
              value={data}
              loading={isLoading || operationDoneLoading}
              scrollHeight="55vh"
              scrollable
              dataKey="id"
              paginator
              className="w-full"
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              header={header}
              globalFilter={globalFilterValue}
              filters={filters}
              rowGroupMode="subheader"
              groupRowsBy="date_of_tech_visit"
              sortMode="single"
              sortField="date_of_tech_visit"
              sortOrder={1}
              rowGroupHeaderTemplate={headerTemplate}
              rowClassName="cursor-pointer"
              onRowClick={(e) => {
                onShowOperationDetails(e?.data?.id);
              }}
            >
              <Column
                header="Sequence Number"
                field="road_sequence"
                headerStyle={{
                  fontSize: "0.5rem",
                }}
              />
              <Column
                header="Trees - Customer"
                body={customerTreeBodyTemplate}
                field="customer.full_name"
                headerStyle={{
                  fontSize: "0.5rem",
                }}
              />
              <Column
                header="Order Status"
                field="order.payment_status"
                body={orderStatusBodyTemplate}
              />

              <Column
                header="Status"
                field="status"
                style={{
                  fontSize: "0.875rem",
                }}
              />
              <Column
                header="Treatment"
                field="treatment.title"
                filter
                filterPlaceholder="Search by name"
                style={{
                  fontSize: "0.875rem ",
                }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicianHomePage;
