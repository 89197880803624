import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useGetAllTechnicianCashReportQuery } from "../services/operation/operationForTechnician";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";

import img from "../../src/assets/tree.png";
import userPhoto from "../../src/assets/user.png";

import { logout } from "../features/auth/authSlice";
import { Button } from "primereact/button";
import { formatCurrency } from "./../utils/formatCurrency";

const TechnicianCashPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { technicianToken, token } = useSelector((state) => state.auth);
  const decodedToken = jwtDecode(technicianToken ? technicianToken : token);

  const {
    data: allOperationData,
    isLoading,
    error: operationTechnicianError,
  } = useGetAllTechnicianCashReportQuery(decodedToken.id, {
    refetchOnMountOrArgChange: true,
  });

  console.log({ allOperationData });

  const navigateOrdersMap = () => {
    navigate("/technician/ordersmap");
  };

  const navigateMap = () => {
    navigate("/technician/map");
  };

  const handleLogOut = () => {
    dispatch(logout());
    navigate("/login");
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between mb-2 bg-none">
        <div className="flex flex-col gap-3 md:flex-row  items-center justify-between w-full bg-[#6e9989]">
          <span className="w-full mr-2 p-input-icon-left">
            {/* <i className="pi pi-search" />
            <InputText
              className="w-full p-inputtext-sm rounded-xl lg:w-1/3"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            /> */}
          </span>
          <div className="flex gap-2">
            {/* <Button
              icon="pi pi-file-pdf"
              className="bg-[#F40F02] border-[#F40F02]"
              onClick={exportPdf}
              rounded
            /> */}
            {decodedToken?.username === "superdamin" && (
              <Button
                icon="pi pi-cog"
                className="bg-gray-500 border-gray-500"
                onClick={() => {
                  navigate("/technician");
                }}
                rounded
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const totalAmountBodyTemplate = (rowData) => {
    const { total_amount_by_credit_card } = rowData;
    return (
      <div className="py-4">{formatCurrency(total_amount_by_credit_card)}</div>
    );
  };

  const totalAmountCashBodyTemplate = (rowData) => {
    const { total_amount_by_cash } = rowData;
    return <div className="py-4">{formatCurrency(total_amount_by_cash)}</div>;
  };

  const totalAmountZelleBodyTemplate = (rowData) => {
    return (
      <div className="py-4">
        {formatCurrency(rowData["total_amount_by_check/zelle"])}
      </div>
    );
  };

  console.log({ decodedToken });

  return (
    <div className="bg-[#3A5D4C] p-2 flex flex-col">
      {decodedToken && (
        <div className="flex items-center justify-between pt-2 bg-[#FFEDED] px-10 rounded-t-[15.83px]">
          <div className="flex justify-center items-end  gap-2 md:border-b-[1.5px] mb-3 border-[#3A5D4C]">
            <img src={img} alt="logo" className="w-[50px] h-[50px]" />
            <span className="text-[#165720] font-bold text-[28px] leading-6 mb-2 hidden md:block">
              Palm Trees
            </span>
          </div>
          <div className="flex gap-5">
            {decodedToken?.username === "superdamin" && (
              <>
                <button
                  className="items-center gap-2 lg:flex lg:justify-center bg-[#6e9989] text-white w-10 md:w-40 rounded-md"
                  onClick={navigateOrdersMap}
                >
                  <i className="text-xl font-semibold pi pi-map"></i>
                  <span className="hidden text-xl lg:flex lg:font-semibold">
                    Orders Map
                  </span>
                </button>
                <button
                  className="items-center gap-2 lg:flex lg:justify-center bg-[#6e9989] text-white w-10 md:w-44 rounded-md"
                  onClick={navigateMap}
                >
                  <i className="text-xl font-semibold pi pi-map-marker"></i>
                  <span className="hidden text-xl lg:flex lg:font-semibold">
                    Operation Map
                  </span>
                </button>
                <div className="flex items-center justify-center gap-2">
                  <span className="text-[#165720] font-black text-[15px] leading-4 lg:flex hidden">
                    {decodedToken?.username}
                  </span>
                  <img
                    src={userPhoto}
                    alt="logo"
                    className="w-[40px] h-[40px] md:mb-2"
                  />
                </div>{" "}
              </>
            )}
            <button className="flex items-center gap-2" onClick={handleLogOut}>
              <span className="hidden md:block">LogOut</span>
              <i className="pi pi-sign-out "></i>
            </button>
          </div>
        </div>
      )}
      <div className="bg-[#FFEDED] w-full rounded-b-[15.83px] ">
        <div className="bg-[#6e9989] mx-5 rounded-[15.83px] mb-5 px-5 py-10">
          <div className="flex justify-center h-full">
            {/* Error State */}
            {operationTechnicianError && (
              <Message
                severity="error"
                text="An error occurred while fetching the data."
              />
            )}
            <DataTable
              value={allOperationData?.data}
              loading={isLoading}
              scrollHeight="55vh"
              scrollable
              dataKey="date_of_tech_visit"
              paginator
              className="w-full"
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              header={header}
              // rowGroupMode="subheader"
              // groupRowsBy="date_of_tech_visit"
              // sortMode="single"
              // sortField="date_of_tech_visit"
              // sortOrder={1}
              // rowGroupHeaderTemplate={headerTemplate}
              // rowClassName="cursor-pointer"
            >
              <Column header="Date" field="date_of_tech_visit" />
              <Column
                header="Total Amount - Cash"
                field="total_amount_by_cash"
                body={totalAmountCashBodyTemplate}
              />
              <Column
                header="Total Amount - Credit Card"
                field="total_amount_by_credit_card"
                body={totalAmountBodyTemplate}
              />
              <Column
                header="Total Amount - Check/Zelle"
                field="total_amount_by_check_zelle"
                body={totalAmountZelleBodyTemplate}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicianCashPage;
