import React, { useState, useEffect, useRef } from "react";
import { fetchCoordinatesFromGeoCode } from "../../services/map/addressToCoordinates";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import OperationDialog from "./../../components/ClientTreatment/OperationDialog";
import { Toast } from "primereact/toast";
import { MultiSelect } from 'primereact/multiselect';
import { useGetAllActiveOperationsQuery } from "../../services/operation/operationServices";
import { useGetAllActiveTreatmentQuery } from "../../services/treatment/treatmentServices";
import { useLocation } from 'react-router-dom';
import "../map/map.css";

const MapService = ({ role }) => {
  const [markers, setMarkers] = useState([{ lat: 0, lng: 0 }]);
  const [selectedMapOption, setSelectedMapOption] = useState();
  const [filteredOperationData, setFilteredOperationData] = useState([]);
  const [noteVisible, setNoteVisible] = useState(false);
  const [roleCheck, setRoleCheck] = useState();
  const [addNoteID, setAddNoteID] = useState("");
  const [addNoteLat, setAddNoteLat] = useState();
  const [addNoteLng, setAddNoteLng] = useState();
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceValues, setServiceValues] = useState([]);
  const toast = useRef(null);
  const location = useLocation();

  const {
    data: allOperationData,
    isFetching,
    refetch,
  } = useGetAllActiveOperationsQuery({
    service: JSON.stringify(selectedServices)
  }, {
    refetchOnMountOrArgChange: true,
  });
  const {
   data: allActiveTreatmentData,
  } = useGetAllActiveTreatmentQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const uniqueServices = [];
    const serviceSet = new Set();

    allActiveTreatmentData?.data?.forEach(item => {
      if (!serviceSet.has(item?.title)) {
        serviceSet.add(item?.title);
        uniqueServices.push(item);
      }
    });

    if(serviceValues.length === 0) {
      setServiceValues(uniqueServices.map(item => ({
        label: item?.title,
        value: item?.id,
      })))
    }
  }, [allOperationData,serviceValues]);

  const useHideElement = (keyword) => {
    return location.pathname.includes(keyword);
  };
  const hideElement = useHideElement('technician');

  const getSeverity = (status, wantTreatRes, paymentStatus, technicianData) => {
    if (status === "Done" && paymentStatus === "Paid in Full") {
      return "#22C55E";
    } else if (status !== null) {
      switch (status) {
        case "Done":
          return "#E12958";
        case "Done Paid":
          return "#22C55E";
        case "Set":
          if (technicianData === null) {
            return "#800080";
          } else {
            return "#FFC42A";
          }
        case "Tentative":
          if (technicianData === null) {
            return "#800080";
          } else {
            return "#9AE0FF";
          }
        default:
          return null;
      }
    } else {
      switch (wantTreatRes) {
        case "no-answer":
          return "gray";
        default:
          return null;
      }
    }
  };

  const editDialog = (selectedMarker) => {
    setNoteVisible(true);
    setAddNoteID(selectedMarker.id);
    setAddNoteLat(selectedMarker.lat);
    setAddNoteLng(selectedMarker.lng);
    const index = markers.findIndex((marker) => {
      if (
        marker.lat === selectedMarker.lat &&
        marker.lng === selectedMarker.lng &&
        marker.id === selectedMarker.id
      ) {
        return true;
      } else {
        return false;
      }
    });
    const newMarkersArray = [
      selectedMarker,
      ...markers.slice(0, index),
      ...markers.slice(index + 1),
    ];
    setMarkers(newMarkersArray);
  };

  useEffect(() => {
    if (role === "admin") {
      setRoleCheck(true);
    }
  }, [role]);

  useEffect(() => {
    if (allOperationData?.data) {
      switch (selectedMapOption) {
        case "today":
          for (let i = 0; i < allOperationData.data.length; i++) {
            if (
              todayFormattedDate === allOperationData.data[i].date_of_tech_visit
            ) {
              filteredOperationData.push(allOperationData.data[i]);
            }
          }
          fetchCoordinatesFromGeoCode(filteredOperationData).then((response) => {
            if (response.length !== 0) {
              setMarkers(response);
            } else {
              setMarkers([{ lat: 0, lng: 0 }]);
            }
          });
          break;
        case "thisWeek":
          for (let i = 0; i <= 6; i++) {
            const today = new Date();
            const currentDate = new Date(today);
            currentDate.setDate(currentDate.getDate() + i);
            const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            for (let i = 0; i < allOperationData.data.length; i++) {
              if (
                formattedCurrentDate ===
                allOperationData.data[i].date_of_tech_visit
              ) {
                filteredOperationData.push(allOperationData.data[i]);
              }
            }
          }
          fetchCoordinatesFromGeoCode(filteredOperationData).then((response) => {
            if (response.length !== 0) {
              setMarkers(response);
            } else {
              setMarkers([{ lat: 0, lng: 0 }]);
            }
          });
          break;
        case "schedule":
          for (let i = 0; i < allOperationData.data.length; i++) {
            if (allOperationData.data[i].date_of_tech_visit === null) {
              filteredOperationData.push(allOperationData.data[i]);
            }
          }
          fetchCoordinatesFromGeoCode(filteredOperationData).then((response) => {
            if (response.length !== 0) {
              setMarkers(response);
            } else {
              setMarkers([{ lat: 0, lng: 0 }]);
            }
          });
          break;
        default:
          fetchCoordinatesFromGeoCode(allOperationData?.data).then((response) => {
            if (response.length !== 0) {
              setMarkers(response);
            } else {
              setMarkers([{ lat: 0, lng: 0 }]);
            }
          });
      }
    }
  }, [allOperationData, selectedMapOption]);

  const currentDate = new Date();
  const todayFormattedDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  function handleSelection(e) {
    setSelectedMapOption(e.target.value);
    setFilteredOperationData([]);
  }

  const mapContainerStyle = {
    width: "75vw",
    height: "70vh",
  };

  const center = {
    lat: markers[0]?.lat,
    lng: markers[0]?.lng,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCRVh8i3NvcSTjn9eZ3avLuP4eapTrXpG4",
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if ( !isLoaded) {
    return <div className="text-xl font-semibold">Loading...</div>;
  }

  const defaultMarkerOptions = {
    openInfoWindowByDefault: true,
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-between">
        <div>
          <select
            id="dateOptions"
            className="h-10 mb-5 text-xl w-60 rounded-xl"
            onChange={(e) => {
              handleSelection(e);
            }}
          >
            <option value="allDates">All Dates</option>
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="schedule">Need to Schedule</option>
          </select>
        </div>
        {!hideElement &&  <div className="flex justify-center items-center">
          <div className="w-[25vw] h-10 mb-5">
            <MultiSelect
              value={selectedServices}
              options={serviceValues}
              onChange={(e) => { 
                console.log(e)
                setSelectedServices(e.value)}}
              placeholder="Select Service"
              display="chip"
              className="w-full rounded-xl"
            />
          </div>
        </div> }
      </div>
      {addNoteID && (
        <OperationDialog
          visible={noteVisible}
          setVisible={setNoteVisible}
          id={addNoteID}
          setId={setAddNoteID}
          lat={addNoteLat}
          lng={addNoteLng}
          refetch={refetch}
        />
      )}
      { isFetching ? (
        <div className="flex items-center justify-center w-[75vw] h-[70vh]">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
      ) : (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
          defaultOptions={{ markerOptions: defaultMarkerOptions }}
        >
          {markers?.map((marker, index) => (
            <MarkerF
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
              icon={{
                path: "M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482C346.693,59.825,286.846,0,213.285,0zM274.865,136.62c0,34.124-27.761,61.884-61.885,61.884c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z",
                fillColor: getSeverity(
                  marker.status,
                  marker.wantTreatRes,
                  marker.paymentStatus,
                  marker.technicianData
                ),
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 0.08,
              }}
              label={{
                text: marker?.customerData?.full_name,
                className: "custom-label",
              }}
              onClick={() => {
                editDialog(marker);
              }}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default MapService;