import "primeicons/primeicons.css";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "./index.css";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import TechnicianHomePage from "./pages/TechnicianHomePage";
import TechnicianDashboard from "./pages/TechnicianDashboard";
import ProtectedRoute from "./Routes/ProtectedPoute";
import TechnicianProtectedRoute from "./Routes/TechnicianProtectedRoute";
import ClientTreatmentTable from "./components/ClientTreatment/ClientTreatmentTable";
import TreatmentTable from "./components/Treatment/TreatmentTable";
import Orders from "./components/Orders/Orders";
import Products from "./components/Products/Products";
import Driver from "./components/Driver/Driver";
import CustomerTable from "./components/Customer/CustomerTable";
import CustomerTableCommu from "./components/Customer/CustomerTableCommu";
import SendTreatment from "./components/ClientTreatment/SendTreatment";
import TreatmentForm from "./components/Treatment/TreatmentForm";
import Map from "./components/Map/Map";
import MapTechnician from "./components/Map/mapTechnician";
import Admins from "./components/admin/Admins";
import Tasks from "./components/tasks/Tasks";
import AddCustomerDialog from "./components/Customer/AddCustomerDialog";
import AddCustomerDialogPublic from "./components/Customer/AddCustomerDialogPublic";
import OrderMapDialog from "./components/Map/OrderMap";
import OrderMapTechnician from "./components/Map/orderMapTechnician";
import OrderPdf from "./components/Customer/OrderPdf";
import TechnicianCashPage from "./pages/TechnicianCashPage";
import AdminOperationTable from "./components/ClientTreatment/AdminOperationTable";
import TechnicianAppContainer from "./components/Driver/TechnicianAppContainer";

function App() {
  const { token } = useSelector((state) => state.auth);
  // const { technicianToken } = useSelector((state) => state.auth);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />}>
            <Route path="/" element={<Orders />} />
            <Route path="/treatment" element={<TreatmentTable />} />
            <Route path="/operation" element={<ClientTreatmentTable />} />
            <Route path="/operation/cash" element={<AdminOperationTable />} />
            <Route path="/orders/:id" element={<OrderPdf />} />
            <Route path="/products" element={<Products />} />
            <Route path="/technicians" element={<Driver />} />
            <Route
              path="/technicians/Details"
              element={<TechnicianAppContainer />}
            />
            <Route path="/customer" element={<CustomerTable />} />
            <Route path="/customerCommu" element={<CustomerTableCommu />} />
            <Route path="/send-treatment" element={<SendTreatment />} />
            <Route path="/admins" element={<Admins />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/map" element={<Map />} />
            <Route path="/addcustomer" element={<AddCustomerDialog />} />
            <Route path="/technician/cash" element={<TechnicianCashPage />} />
            <Route path="/ordermap" element={<OrderMapDialog />} />
          </Route>
        </Route>
        <Route path="/new/addcustomer" element={<AddCustomerDialogPublic />} />
        <Route
          path="/login"
          element={token ? <Navigate to="/"></Navigate> : <LoginPage />}
        />
        <Route path="/forms/treatments/:token" element={<TreatmentForm />} />
        <Route path="/" element={<TechnicianProtectedRoute />}>
          <Route path="/" element={<TechnicianDashboard />}>
            <Route path="/technician" element={<TechnicianHomePage />} />
            <Route path="/technician/cash" element={<TechnicianCashPage />} />
            <Route path="/technician/map" element={<MapTechnician />} />
            <Route
              path="/technician/ordersmap"
              element={<OrderMapTechnician />}
            />
          </Route>
        </Route>
      </Route>
    )
  );

  return (
    <div className="bg-[--main-green-background] min-h-screen">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
