import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAddress } from "../../features/customer/customerActions";
import MapAddCustomer from "./../Map/mapAddCustomer";

const EditCustomerAddress = ({
  data: CustomerData,
  isLoading,
  error,
  refetch,
  toast,
  setState,
}) => {
  const defaultValues = {
    postalCode: "",
    state: "",
    street: "",
    town: "",
    geocode: "",
  };

  console.log({ CustomerData });

  const dispatch = useDispatch();

  const { updateLoading, addressData } = useSelector((state) => state.customer);

  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const prevAddressDataRef = useRef();

  useEffect(() => {
    console.log(addressData);
    if (addressData && prevAddressDataRef.current !== addressData) {
      setValue("street", `${addressData?.street}`);
      setValue("town", `${addressData?.town}`);
      setValue("postalCode", `${addressData?.postalCode}`);
      setValue("state", `${addressData?.state}`);
      setValue("geocode", `${addressData?.geocode}`);
      prevAddressDataRef.current = addressData;
    }
  }, [addressData, setValue]);

  const onEdit = (data) => {
    const { street, state, town, postalCode, geocode } = data;

    const obj = {
      id: CustomerData?.data?.id,
      postal_code: postalCode,
      state,
      street,
      town,
      geo_code: geocode,
    };

    dispatch(updateCustomerAddress(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        console.log("Address updated");
        reset();
        refetch();
        setState("CustomerDetails");
        toast.current.show({
          severity: "success",
          summary: "Address updated",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onEdit)} className="my-8">
          <MapAddCustomer />
          <div className="flex items-center justify-center mt-8">
            <Button
              label={updateLoading ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={updateLoading ? "pi pi-spin pi-spinner" : "pi pi-check"}
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </>
  );
};

export default EditCustomerAddress;
