import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";

import styles from "./TreatmentTable.module.css";

import { useGetAllTreatmentQuery } from "../../services/treatment/treatmentServices";
import {
  addTreatment,
  deleteTreatment,
} from "../../features/treatment/treatmentActions";

import TreatmentEdit from "./TreatmentEdit";

import { formatCurrency } from "../../utils/formatCurrency";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";

const TreatmentTableContent = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [deletedTreatmentVisible, setDeletedTreatmentVisible] = useState(false);
  const [editTreatmentVisible, setEditTreatmentVisible] = useState(false);
  const [deletedTreatmentId, setDeletedTreatmentId] = useState(null);
  const [editTreatmentId, setEditTreatmentId] = useState(null);
  const [visibleDescription, setVisibleDescription] = useState(false);
  const [description, setDescription] = useState("");

  const toast = useRef(null);

  const dispatch = useDispatch();

  const { addLoading } = useSelector((state) => state.treatment);

  const {
    data: treatmentsData,
    error,
    isLoading,
    refetch,
  } = useGetAllTreatmentQuery({ refetchOnMountOrArgChange: true });

  const services = [
    { name: "Wrapping", code: "Wrapping" },
    { name: "Unwrapping", code: "Unwrapping" },
    { name: "Spring", code: "Spring" },
    { name: "Mid Summer", code: "Mid Summer" },
    { name: "Pre Winter", code: "Pre Winter" },
  ];

  const defaultValues = {
    title: "",
    description: "",
    date: "",
    service: "",
    season: "",
    first_tree_price: "1",
    additional_price: "1",
    general_tree_price: "1",
    subject: "",
    form_text: "",
    travel_surcharge: "0",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const { id } = rowData;
    return (
      <div className="flex items-center justify-between m-2">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          size="small"
          onClick={() => onEdit(id)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          size="small"
          onClick={() => onDelete(id)}
        />
      </div>
    );
  };

  const onSubmit = (data) => {
    const {
      date,
      service,
      season,
      first_tree_price,
      additional_price,
      description,
      is_active,
      subject,
      form_text,
      travel_surcharge,
    } = data;

    const formattedDate = date.getFullYear();

    const obj = {
      title: `${service.name} - ${formattedDate} - ${season} `,
      date: formattedDate.toString(),
      service: service.name,
      season: season,
      description: description,
      is_active,
      subject,
      form_text,
      first_tree_price: Number(first_tree_price) + Number(travel_surcharge),
      additional_price: Number(additional_price),
    };

    dispatch(addTreatment(obj)).then((res) => {
      if (res.payload.status === 200) {
        reset();
        refetch();
        setVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const onDelete = (id) => {
    setDeletedTreatmentId(id);
    setDeletedTreatmentVisible(true);
  };

  const onDeleteHandler = () => {
    dispatch(deleteTreatment({ id: deletedTreatmentId })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        setDeletedTreatmentVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const onEdit = (id) => {
    setEditTreatmentId(id);
    setEditTreatmentVisible(true);
  };

  const onDescriptionShow = (desc) => {
    setVisibleDescription(true);
    setDescription(desc?.description);
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDeletedTreatmentVisible(false)}
        className="p-button-text"
        text
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => onDeleteHandler()}
        text
      />
    </div>
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        <div>
          <Button
            icon="pi pi-plus"
            label="Add Treatment"
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            size="small"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>
    );
  };

  const titleBodyTemplate = (rowData) => {
    const title =
      rowData.title.charAt(0).toUpperCase() + rowData.title.slice(1);
    return <div className="py-4">{title}</div>;
  };

  const serviceBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.service}</div>;
  };

  const dateBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.date}</div>;
  };

  // const treePriceBodyTemplate = (rowData) => {
  //   return (
  //     <div className="py-4">
  //       {rowData.general_tree_price
  //         ? formatCurrency(Number(rowData.general_tree_price))
  //         : "-"}
  //     </div>
  //   );
  // };

  const activeBodyTemplate = (rowData) => {
    const { is_active } = rowData;
    return (
      <div className="text-sm">
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600": is_active === true,
            "false-icon pi-times-circle text-red-500": is_active === false,
          })}`}
        ></i>
      </div>
    );
  };

  const firstTreePriceBodyTemplate = (rowData) => {
    return (
      <div className="py-4">
        {rowData.first_tree_price
          ? formatCurrency(Number(rowData.first_tree_price))
          : "-"}
      </div>
    );
  };

  const additionalTreePriceBodyTemplate = (rowData) => {
    return (
      <div className="py-4">
        {rowData.additional_price
          ? formatCurrency(Number(rowData.additional_price))
          : "-"}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.data?.message}</p>
      </div>
    );
  }

  if (treatmentsData)
    return (
      <div className={`mx-8 ${styles.table}`}>
        <Toast ref={toast} />
        <Dialog
          header="Add Treatment"
          visible={visible}
          style={{ width: "45vw" }}
          onHide={() => setVisible(false)}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="my-8">
            <Controller
              name="date"
              control={control}
              rules={{ required: "Date is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Year
                  </label>
                  <span>
                    <Calendar
                      view="year"
                      dateFormat="yy"
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      inputClassName="rounded-xl"
                      minDate={new Date()}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="service"
              control={control}
              rules={{ required: "Service is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Service
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={services}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="season"
              control={control}
              rules={{ required: "Title is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Title
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="subject"
              control={control}
              rules={{ required: "Subject is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Subject
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      maxLength={70}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <p>{field?.value?.length}/70 characters used</p>
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Email
                  </label>
                  <span className="my-2">
                    <p className="text-white">Hello dear customer,</p>
                    <InputTextarea
                      id={field.name}
                      value={field.value}
                      maxLength={600}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      rows={5}
                      cols={30}
                      placeholder="Description of Email"
                    />
                    <p className="text-white">{`{{ formLink }}`}</p>
                    <p className="text-white">Thank you, </p>
                    <p className="text-white">Kevin</p>
                  </span>
                  <p>{field?.value?.length}/600 characters used</p>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="form_text"
              control={control}
              rules={{ required: "Description of Form is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Form
                  </label>
                  <span>
                    <InputTextarea
                      id={field.name}
                      value={field.value}
                      maxLength={1000}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      rows={5}
                      cols={30}
                      placeholder="Description of Form"
                    />
                    <p>{field?.value?.length}/1000 characters used</p>
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="travel_surcharge"
              control={control}
              rules={{ required: "Travel Surcharge is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Travel Surcharge
                  </label>
                  <span>
                    <InputNumber
                      min={0}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onValueChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="first_tree_price"
              control={control}
              rules={{ required: "First Tree Price is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    First Tree Price
                  </label>
                  <span>
                    <InputNumber
                      min={1}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onValueChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="additional_price"
              control={control}
              rules={{ required: "Additional Trees Price is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Additional Trees Price
                  </label>
                  <span>
                    <InputNumber
                      min={1}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onValueChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="is_active"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Active
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Active"
                        name="isActive"
                        value={true}
                        checked={field.value === true}
                        onChange={() => field.onChange(true)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Active" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="inActive"
                        name="isActive"
                        value={false}
                        onChange={() => field.onChange(false)}
                        checked={field.value === false}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                      />
                      <label htmlFor="inActive" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <div className="flex items-center justify-center">
              <Button
                label={addLoading ? "" : "Submit"}
                className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                type="submit"
                icon={addLoading ? "pi pi-spin pi-spinner" : "pi pi-check"}
              />
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={visibleDescription}
          onHide={() => setVisibleDescription(false)}
          header="Description"
          style={{
            width: "50vw",
          }}
        >
          <pre className="font-sans whitespace-pre-line">
            {description ? (
              description
            ) : (
              <p className="text-red-600">There No Description</p>
            )}
          </pre>
        </Dialog>
        {editTreatmentId && (
          <TreatmentEdit
            editTreatmentVisible={editTreatmentVisible}
            setEditTreatmentVisible={setEditTreatmentVisible}
            editTreatmentId={editTreatmentId}
            refetch={refetch}
            toast={toast}
          />
        )}
        <Dialog
          header="Delete"
          visible={deletedTreatmentVisible}
          style={{ width: "50vw" }}
          onHide={() => setDeletedTreatmentVisible(false)}
          footer={footerContent}
        >
          <p className="m-0 text-center">
            Are you sure to delete this treatment?
          </p>
        </Dialog>
        <DataTable
          value={treatmentsData.data}
          dataKey="id"
          scrollable
          scrollHeight="68vh"
          style={{ minWidth: "50rem" }}
          header={header}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onRowClick={(e) => {
            onDescriptionShow(e?.data);
          }}
          rowClassName="cursor-pointer"
        >
          <Column
            field="title"
            header="Title"
            body={titleBodyTemplate}
          ></Column>
          <Column
            field="service"
            header="Service"
            body={serviceBodyTemplate}
          ></Column>
          <Column field="date" header="Year" body={dateBodyTemplate}></Column>
          <Column
            field="is_active"
            header="Active"
            body={activeBodyTemplate}
          ></Column>
          <Column
            field="first_tree_price"
            header="First Tree Price + Travel Surcharge"
            body={firstTreePriceBodyTemplate}
          ></Column>
          <Column
            field="additional_price"
            header="Additional Tree Price"
            body={additionalTreePriceBodyTemplate}
          ></Column>
          <Column body={actionBodyTemplate} header="Actions"></Column>
        </DataTable>
      </div>
    );
};

export default TreatmentTableContent;
