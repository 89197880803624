import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import "./ClientTreatmentTable.module.css";

import { assignTechnician } from "../../features/operation/operationActions";
import { useGetAllOperationsQuery } from "../../services/operation/operationServices";
import { useGetAllTechniciansQuery } from "../../services/technician/technicianServices";

import OperationDialog from "./OperationDialog";
import { useMediaQuery } from "react-responsive";

const ClientTreatmentTableContent = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [operationId, setOperationId] = useState("");
  const [filters] = useState({
    "treatment.title": { value: null, matchMode: FilterMatchMode.CONTAINS },
    want_treat_response: { value: null, matchMode: FilterMatchMode.EQUALS },
    "customer.state": { value: null, matchMode: FilterMatchMode.IN },
    "customer.town": { value: null, matchMode: FilterMatchMode.IN },
    "order.payment_status": {
      value: null,
      matchMode: FilterMatchMode.NOT_EQUALS,
    },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [sortField, setSortField] = useState("updated_at");
  const [sortOrder, setSortOrder] = useState(-1);
  const [operationDetailsVisible, setOperationDetailsVisible] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const navigate = useNavigate();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const toast = useRef(null);

  const dispatch = useDispatch();

  const { assignTechnician: assignTechnicianState } = useSelector(
    (state) => state.operation
  );

  const defaultValues = {
    technicianId: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const {
    data: allOperationData,
    isLoading,
    error,
    refetch,
  } = useGetAllOperationsQuery("operationsDetails", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (allOperationData && allOperationData.data) {
      const towns = allOperationData?.data?.map((item) => item?.customer?.town);
      const uniqueTowns = [...new Set(towns)].filter(
        (town) => town !== "undefined"
      );
      setCities(uniqueTowns);
    }
  }, [allOperationData]);

  const citiesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={cities}
        onChange={(e) => options.filterCallback(e.value)}
        // optionLabel="name"
        placeholder="Any"
      />
    );
  };

  useEffect(() => {
    if (allOperationData && allOperationData.data) {
      const states = allOperationData?.data?.map(
        (item) => item?.customer?.state
      );
      const uniqueStates = [...new Set(states)].filter(
        (town) => town !== "undefined"
      );
      setStates(uniqueStates);
    }
  }, [allOperationData]);

  const statesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={states}
        onChange={(e) => options.filterCallback(e.value)}
        // optionLabel="name"
        placeholder="Any"
      />
    );
  };

  const onSortChange = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const { data: techniciansData, isLoading: techniciansLoading } =
    useGetAllTechniciansQuery("TechniciansDetails", {
      refetchOnMountOrArgChange: true,
    });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const onShowOperationDetails = (id) => {
    setOperationId(id);
    setOperationDetailsVisible(true);
  };

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.data?.message}</p>
      </div>
    );
  }

  const dateBodyTemplate = (rowData) => {
    const { date_of_tech_visit } = rowData;
    return (
      <div className="m-2 text-sm">
        <span>{date_of_tech_visit}</span>
      </div>
    );
  };

  const customerTreeBodyTemplate = (rowData) => {
    const { total_number_of_trees, customer, want_treat_response } = rowData;
    return (
      <div className="flex items-center justify-start my-4 ml-2 text-sm">
        <i
          className={`${classNames("pi", {
            "pi-circle-fill mr-1 text-blue-600": want_treat_response === "true",
            "pi-circle-fill mr-1 text-red-500": want_treat_response === "false",
            "pi-circle-fill mr-1 text-gray-600":
              want_treat_response === "no-answer",
          })}`}
        ></i>
        {total_number_of_trees} - {customer.full_name}
      </div>
    );
  };

  const assignedToBodyTemplate = (rowData) => {
    const { technician } = rowData;
    return (
      <div className="flex items-center justify-center text-sm">
        {technician?.full_name}
      </div>
    );
  };

  const orderStatusBodyTemplate = (rowData) => {
    return (
      <div className="flex items-center justify-center mx-4 text-sm">
        {rowData.order?.payment_status}
      </div>
    );
  };

  const wantTreatmentBodyTemplate = (rowData) => {
    return (
      <div className="text-sm">
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600":
              rowData.want_treat_response === "true",
            "false-icon pi-times-circle text-red-500":
              rowData.want_treat_response === "false",
            "pi-minus-circle text-gray-600":
              rowData.want_treat_response === "no-answer",
          })}`}
        ></i>
      </div>
    );
  };

  const wantTreatmentRowFilterTemplate = (options) => {
    const filterOptions = [
      { label: "Not Response", value: "no-answer" },
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ];

    return (
      <Dropdown
        value={options.value}
        options={filterOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Want Treatment"
        className="w-full"
        optionLabel="label"
      />
    );
  };

  const statusRowFilterTemplate = (options) => {
    const filterOptions = [
      { label: "Tentative", value: "Tentative" },
      { label: "Set", value: "Set" },
      { label: "Done", value: "Done" },
    ];

    return (
      <Dropdown
        value={options.value}
        options={filterOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Status"
        className="w-full"
        optionLabel="label"
        optionValue="value"
      />
    );
  };

  const orderStatusRowFilterTemplate = (options) => {
    const filterOptions = [
      { label: "Not Paid in Full", value: "Paid in Full" },
    ];

    return (
      <Dropdown
        value={options.value}
        options={filterOptions}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Order Status"
        className="w-full"
        optionLabel="label"
        optionValue="value"
      />
    );
  };

  const onSubmit = (data) => {
    const obj = { technicianId: data.technicianId.code, operationId };
    dispatch(assignTechnician(obj)).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        setVisible(false);
        reset();
        setOperationId("");
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex items-center justify-between w-full">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
          <div>
            <Button
              icon="pi pi-dollar"
              label="Cash"
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl mr-3"
              size="small"
              onClick={() => {
                navigate("/operation/cash");
              }}
            />
            <Button
              icon="pi pi-map"
              label="Location on Map"
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl mr-3"
              size="small"
              onClick={() => {
                navigate("/map");
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center h-full">
      <Toast ref={toast} />
      <Dialog
        header="Assign a technician"
        visible={visible}
        style={
          isDesktop ? { width: "35vw" } : isMobile ? { width: "90vw" } : {}
        }
        onHide={() => setVisible(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <Controller
            name="technicianId"
            control={control}
            rules={{ required: "Technician is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Select a technician:
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={
                      techniciansData
                        ? techniciansData.data.map((technician) => ({
                            name: technician.full_name,
                            code: technician.id,
                          }))
                        : []
                    }
                    loading={techniciansLoading}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    placeholder="Select a Technician"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              label={assignTechnicianState ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={
                assignTechnicianState ? "pi pi-spin pi-spinner" : "pi pi-check"
              }
            />
          </div>
        </form>
      </Dialog>
      {operationId && (
        <OperationDialog
          visible={operationDetailsVisible}
          setVisible={setOperationDetailsVisible}
          id={operationId}
          setId={setOperationId}
          refetch={refetch}
        />
      )}
      <DataTable
        value={allOperationData?.data}
        loading={isLoading}
        scrollHeight="58vh"
        scrollable
        dataKey="id"
        paginator
        className="w-full"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        header={header}
        globalFilter={globalFilterValue}
        filters={filters}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSortChange}
        onRowClick={(e) => {
          onShowOperationDetails(e?.data?.id);
        }}
        rowClassName="cursor-pointer"
      >
        <Column
          header="Trees - Customer"
          body={customerTreeBodyTemplate}
          field="customer.full_name"
          headerStyle={{
            fontSize: "0.5rem",
          }}
        />
        <Column header="Treatment" field="treatment.title" filter />
        <Column
          header="State"
          field="customer.state"
          filter
          filterField="customer.state"
          filterPlaceholder="Search by State"
          style={{
            fontSize: "0.875rem ",
          }}
          filterElement={statesFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column
          header="City"
          field="customer.town"
          filter
          filterPlaceholder="Search by City"
          style={{
            fontSize: "0.875rem ",
          }}
          filterElement={citiesFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column
          header="Order Status"
          field="order.payment_status"
          body={orderStatusBodyTemplate}
          filter
          filterElement={orderStatusRowFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column
          header="Assigned To"
          field="technician.full_name"
          body={assignedToBodyTemplate}
        />
        <Column
          header="Date"
          field="date_of_tech_visit"
          body={dateBodyTemplate}
        />
        <Column
          header="Status"
          field="status"
          style={{
            fontSize: "0.875rem",
          }}
          filter
          filterElement={statusRowFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column
          header="Want Treatment"
          field="want_treat_response"
          body={wantTreatmentBodyTemplate}
          dataType="boolean"
          filter
          filterElement={wantTreatmentRowFilterTemplate}
          showFilterMatchModes={false}
        />
      </DataTable>
    </div>
  );
};

export default ClientTreatmentTableContent;
