// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
// authService.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";
export const operationApi = createApi({
  reducerPath: "operationApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

  }),

  endpoints: (builder) => ({
    getAllOperations: builder.query({ query: () => `treatment-ops` }),
    getAllActiveOperations: builder.query({
      query: ({ service }) => {
        return {
          url: `treatment-ops?treatactive=true&treatments=${service}`,
        };
      },
    }),
    getOperation: builder.query({ query: (id) => `treatment-ops/${id}` }),
    getCashOperation: builder.query({
      query: (id) => `cash-report/treatment-ops`,
    }),
  }),
});

export const {
  useGetAllOperationsQuery,
  useGetAllActiveOperationsQuery,
  useGetOperationQuery,
  useGetCashOperationQuery,
} = operationApi;
