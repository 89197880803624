import { createAsyncThunk } from "@reduxjs/toolkit";

import { instance } from "../../axios/axios";

export const addOrderLine = createAsyncThunk(
  "orderLine/create",
  async (
    {
      order_id,
      product_id,
      item_type,
      line_item_quantity,
      notes,
      unit_price_override,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status: reqStatus } = await instance.post(
        `orders/${order_id}/line-items`,
        [
          {
            product_id,
            item_type,
            line_item_quantity,
            notes,
            unit_price_override,
          },
        ],
        config
      );
      return { data, reqStatus };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateOrderLine = createAsyncThunk(
  "orderLine/update",
  async (
    {
      id,
      order_id,
      product_id,
      item_type,
      line_item_quantity,
      notes,
      unit_price_override,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status: reqStatus } = await instance.patch(
        `line-items/${id}`,
        {
          order_id,
          product_id,
          item_type,
          line_item_quantity,
          notes,
          unit_price_override,
        },
        config
      );
      return { data, reqStatus };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOrderLine = createAsyncThunk(
  "orderLine/delete",
  async ({ order_line_id }, { rejectWithValue, getState }) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.delete(
        `line-items/${order_line_id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
