// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "./../../utils/apiURL";

export const operationForTechnicianApi = createApi({
  reducerPath: "operationForTechnicianApi",
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    keepUnusedDataFor: 0,
    refetchOnMountOrArgChange: true,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
        ? getState().auth.token
        : getState().auth.technicianToken;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllOperationForTechnician: builder.query({
      query: (id) => `treatment-ops/tech/${id}`,
    }),
    getAllOrdersForTechnician: builder.query({
      query: (id) => `technicians/${id}/orders?exclude_type=Service`,
    }),
    getAllOperationWithSequence: builder.query({
      query: (id) => `/treatment-ops/sequence/tech/${id}`,
    }),
    getAllOperationWithDoneSequence: builder.query({
      query: (id) => `/treatment-ops/done/tech/${id}`,
    }),
    getAllTechnicianCashReport: builder.query({
      query: (id) => `/treatment-ops/cash-report/tech/${id}`,
    }),
  }),
});

export const {
  useGetAllOperationForTechnicianQuery,
  useGetAllOrdersForTechnicianQuery,
  useGetAllOperationWithSequenceQuery,
  useGetAllOperationWithDoneSequenceQuery,
  useGetAllTechnicianCashReportQuery,
} = operationForTechnicianApi;
