import { createSlice } from "@reduxjs/toolkit";

import {
  addCustomer,
  addPublicCustomer,
  deleteCustomer,
  getFilterCustomerByProduct,
  updateCustomer,
  updateCustomerAddress,
} from "./customerActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
  addressData: {},
  publicAddLoading: false,
  deleteLoading: false,
  filterCustomerLoading: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setAddressData: (state, action) => {
      state.addressData = action.payload;
    },
    removeAddressData: (state) => {
      state.addressData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCustomer.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addCustomer.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addCustomer.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateCustomer.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateCustomerAddress.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateCustomerAddress.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateCustomerAddress.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(addPublicCustomer.pending, (state) => {
        state.publicAddLoading = true;
      })
      .addCase(addPublicCustomer.fulfilled, (state) => {
        state.publicAddLoading = false;
      })
      .addCase(addPublicCustomer.rejected, (state) => {
        state.publicAddLoading = false;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.deleteLoading = false;
      })
      .addCase(getFilterCustomerByProduct.pending, (state) => {
        state.filterCustomerLoading = true;
      })
      .addCase(getFilterCustomerByProduct.fulfilled, (state) => {
        state.filterCustomerLoading = false;
      })
      .addCase(getFilterCustomerByProduct.rejected, (state) => {
        state.filterCustomerLoading = false;
      });
  },
});

export default customerSlice.reducer;
export const { setAddressData, removeAddressData } = customerSlice.actions;
